var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"mx-2",attrs:{"dark":"","color":"indigo"},on:{"click":function($event){_vm.$refs.dialogCreate.shown=true}}},[_vm._v(" Create Qualification... ")]),_c('tutti-dialog',{ref:"dialogCreate",attrs:{"title":"Create Qualification Type","maxWidth":"500","actions":[
            {
                label: 'Create',
                color: 'indigo darken-1',
                disableByRule: true,
                text: true,
                onclick: _vm.createQualificationType
            },
            {
                label: 'Cancel',
                color: 'grey darken-1',
                text: true
            }
        ]},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"filled":"","dense":"","prepend-icon":"mdi-label","label":"Name","rules":[_vm.rules.required]},model:{value:(_vm.newQualParams.Name),callback:function ($$v) {_vm.$set(_vm.newQualParams, "Name", $$v)},expression:"newQualParams.Name"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"filled":"","dense":"","prepend-icon":"mdi-message-text","label":"Description","rules":[_vm.rules.required]},model:{value:(_vm.newQualParams.Description),callback:function ($$v) {_vm.$set(_vm.newQualParams, "Description", $$v)},expression:"newQualParams.Description"}})],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"hide-details":"","color":"indigo","label":"AutoGranted"},model:{value:(_vm.newQualParams.AutoGranted),callback:function ($$v) {_vm.$set(_vm.newQualParams, "AutoGranted", $$v)},expression:"newQualParams.AutoGranted"}})],1)],1)]},proxy:true}])}),_c('tutti-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }